<template>
	<a-modal v-model="show" title="共享分类设置" :width="600" :footer="null" @cancel="() => {
			$emit('success');
			show = false;
		}
		" centered destroyOnClose>
		<div>
			<a-button type="primary" @click="addRow" icon="plus">添加新分类</a-button>
		</div>
		<a-table :scroll="{ x: '100%',y:'300px' }" :style="{ background: '#FFF' }" size="small" :columns="keys" row-key="categoryId"
			ref="list" :data-source="category" :loading="loading" :bordered="false" :pagination="false">
			<!--名称-->
			<template slot-scope="text, row, index" slot="name">
				<a-input v-model="row.name" v-if="row.edit" @pressEnter="confirmRow(row, index)"></a-input>
				<span v-else>{{ row.name }}</span>
			</template>
			<!--排序-->
			<template slot-scope="text, row, index" slot="sort">
				<a-input-number v-model="row.sort" align="center" v-if="row.edit"
					@pressEnter="confirmRow(row, index)"></a-input-number>
				<span v-else>{{ row.sort }}</span>
			</template>
			<!--操作-->
			<template slot-scope="text, row, index" slot="action">
				<a-button-group shape="circle" size="small">
					<a-tooltip title="编辑" v-if="!row.edit">
						<a-button icon="edit" @click="editRow(row, index)"></a-button>
					</a-tooltip>
					<a-tooltip title="保存" v-else>
						<a-button icon="check" @click="confirmRow(row, index)"></a-button>
					</a-tooltip>
					<a-tooltip title="取消" v-if="row.edit"><a-button icon="close"
							@click="delRow(row, index)"></a-button></a-tooltip>
					<a-tooltip title="删除" v-else><a-button icon="delete" @click="delRow(row, index)"></a-button></a-tooltip>
				</a-button-group>
			</template>
		</a-table>
		<a-modal title="添加分类" :visible="visible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model>
				<a-row>
					<a-col span="6" v-if="getOrganId() == 0">
						<a-form-model-item label="所属单位" label-position="top" prop="organId">
							<a-select v-model="formData.organId" style="width:100%" placeholder="请选择单位">
								<a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{
									organ.name }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="15">
					<a-col span="10">
						<a-form-model-item label="分类名称">
							<a-input v-model="formData.name"></a-input>
						</a-form-model-item>
					</a-col>
					<a-col span="10">
						<a-form-model-item label="排序">
							<a-input type="Number" v-model="formData.sort"></a-input>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
export default {
	data() {
		return {
			show: false,
			loading: false,
			visible: false,
			organId: apiUtil.getOrganId(),
			category: [],
			editname: [],
			organList: apiUtil.getOrganList(),
			formData: {
				name: '',
				organId: apiUtil.getOrganId(),
				sort: ''
			},
			keys: [
				{ title: '分类名称', scopedSlots: { customRender: 'name' }, width: 260, ellipsis: true },
				{ title: '排序', scopedSlots: { customRender: 'sort' }, align: 'center', width: 80, ellipsis: true, sorter: (a, b) => a.sort - b.sort },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
			]
		};
	},
	methods: {
		...apiUtil,
		// 获取数据
		getList() {
			this.loading = true;
			if (this.organId == 0) {
				this.organId = '';
			}
			this.http
				.get(`/platform/scope/category`, { organId: this.organId })
				.then(ret => {
					ret.data.forEach(item => (item.edit = false));
					this.category = ret.data;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		// 添加
		addRow() {
			this.visible = true;
			this.formData.name = '';
			this.formData.sort = 0;
		},
		// 确定
		handleOk() {
			utils.showSpin();
			this.visible = false;
			request
				.post('/platform/scope/category-save', this.formData)
				.then(ret => {
					if (ret.code == 200) {
						utils.success('操作成功！').then(() => {
							this.getList();
						});
					}
				})
				.catch(error => {
					this.visible = false;
					console.log(error);
				});
		},
		// 返回
		handleCancel() {
			this.visible = false;
		},

		// 删除
		delRow(row, index) {
			if (!row.categoryId) {
				this.category.splice(index, 1);
			}
			if (row.edit) {
				row.edit = false;
				row.name = this.editname;
				return;
			}
			utils.confirm('您确认要删除此分类吗?').then(() => {
				utils.showSpin();
				request.delete('/platform/scope/category-del/' + row.categoryId).then(ret => {
					this.getList();
				});
			});
		},
		// 编辑
		editRow(row, index) {
			row.edit = true;
			this.editname = row.name;
		},
		// 保存
		confirmRow(row, index) {
			utils.showSpin();
			// console.log(row);
			request.post('/platform/scope/category-save', row).then(ret => {
				row.edit = false;
				this.getList();
			});
		},
		_show() {
			this.show = true;
			this.getList();
		},
		_close() {
			console.log(1);
			this.show = false;
		}
	}
};
</script>
<style>
.footer {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
</style>
